@font-face {
  font-family: Ana\'s Faded Typewriter;
  src: url("AnasFadedTypewriter.993cd040.eot");
  src: url("AnasFadedTypewriter.993cd040.eot#iefix") format("embedded-opentype"), url("AnasFadedTypewriter.7016eaf3.woff2") format("woff2"), url("AnasFadedTypewriter.95121376.woff") format("woff"), url("AnasFadedTypewriter.cc0e7e93.svg#AnasFadedTypewriter") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

html {
  scroll-behavior: smooth;
}

body {
  color: #fff;
  text-align: center;
  background-color: #000;
  flex-direction: column;
  align-items: center;
  font-family: Ana\'s Faded Typewriter, sans-serif;
  font-size: 16px;
  display: flex;
}

.hero {
  box-sizing: border-box;
  color: #fff;
  background: #000;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  display: flex;
}

#header {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.hero_content {
  flex-direction: column;
  justify-content: center;
  width: 90%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.txt {
  font-family: Ana\'s Faded Typewriter;
  font-size: 7em;
}

.hero-title {
  max-width: 16em;
  margin: 0;
  font-size: 12vh;
  font-weight: bold;
  line-height: .9;
}

@media only screen and (width >= 32em) {
  .hero-title {
    font-size: 16vh;
  }
}

.hero-footer {
  margin-bottom: 1em;
  display: flex;
}

article {
  text-align: left;
  max-width: 36em;
  margin: 0 auto;
  padding: 5em 1em;
}

article #anchor {
  font-family: Ana\'s Faded Typewriter;
}

article p {
  color: #eee;
  padding-bottom: 15px;
  font-family: Ana\'s Faded Typewriter;
  font-size: 17px;
  font-weight: 100;
  line-height: 1.7;
}

article a {
  color: #fff;
  text-decoration: none;
}

article a:hover {
  text-decoration: underline;
}

.button-down {
  z-index: 1;
  place-self: center;
  width: 50px;
  height: 50px;
  position: relative;
  bottom: 10px;
}

.button-down a {
  cursor: pointer;
}

.menu {
  z-index: 100;
  background-color: #000;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  padding: 20px;
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
}

.menu a {
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  padding: 15px 20px;
  font-size: .8rem;
  font-weight: 100;
  text-decoration: none;
  display: inline-block;
}

.menu a:hover {
  background: #393939bf;
}

.item a {
  text-transform: capitalize;
}

@media (width >= 900px) {
  .navbar {
    display: flex;
  }

  .navbar .left, .navbar .right {
    flex: 1;
    display: flex;
  }

  .navbar .link {
    width: auto;
  }

  .navbar .right {
    justify-content: flex-end;
  }

  .navbar .toggle {
    display: none;
  }
}

.navbar div {
  display: none;
}

@media (width >= 900px) {
  .navbar div {
    display: flex;
  }
}

@media (width <= 900px) {
  .menu.active {
    border-bottom: 1px solid #fff;
  }

  .navbar.active .toggle {
    color: #fff;
  }

  .navbar.active div {
    display: block;
  }

  .item {
    border-bottom: 1px solid #4b4848;
  }

  .item a {
    padding: 10px;
  }

  .item:hover {
    opacity: .9;
  }

  .item:hover a {
    background: none;
  }

  .item .nav-subtext {
    opacity: 1;
    background: none;
    padding: 0 20px 15px;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    transition: none;
    position: relative;
    top: 0;
    right: 0;
  }

  .item .right {
    right: 0;
  }

  .item:hover .nav-subtext {
    opacity: 1;
    top: 0 !important;
  }

  .item:nth-child(5) {
    border-bottom: 0 solid #4b4848;
  }
}

.menu {
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.toggle {
  order: 1;
}

.item.button {
  order: 2;
}

.item {
  text-align: center;
  order: 3;
  width: 100%;
  display: none;
}

.item.active {
  display: block;
}

@media (width >= 900px) {
  .menu {
    justify-content: flex-end;
  }

  .item {
    width: auto;
    display: block;
  }

  .toggle {
    display: none;
  }

  .logo {
    order: 0;
  }

  .item {
    order: 1;
  }

  .button {
    order: 2;
  }

  .menu li {
    padding: 15px 10px;
  }

  .menu li.button {
    padding-right: 0;
  }
}

.nav-subtext {
  opacity: 0;
  opacity: 0;
  letter-spacing: .1em;
  background: #2d2d2d;
  padding: 15px 20px;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 100;
  transition: all .3s ease-in;
  position: absolute;
  top: 100px;
  right: 115px;
}

.right {
  right: 30px;
}

.item:hover .nav-subtext {
  opacity: 1;
  top: 90px;
}

.roboto {
  font-family: Roboto, sans-serif;
}

.roboto a {
  color: #fff;
  font-weight: 400;
}

a.chevron-down:hover img {
  fill: red;
}

p {
  align-items: center;
  display: flex;
}

.material-symbols-outlined {
  margin-right: 10px;
}

.email {
  flex-flow: wrap;
  justify-content: flex-start;
  align-items: center;
  padding: 5px 0;
  display: flex;
  position: relative;
}

.email svg {
  margin-right: 10px;
}

.email p {
  padding: 0 10px;
}

.contact {
  padding: 5px 0;
  position: relative;
}

/*# sourceMappingURL=index.657836e4.css.map */
