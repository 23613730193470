@font-face {
    font-family: "Ana's Faded Typewriter";
    src: url("../public/AnasFadedTypewriter.eot");
    src: url("../public/AnasFadedTypewriter.eot?#iefix")
            format("embedded-opentype"),
        url("../public/AnasFadedTypewriter.woff2") format("woff2"),
        url("../public/AnasFadedTypewriter.woff") format("woff"),
        url("../public/AnasFadedTypewriter.svg#AnasFadedTypewriter")
            format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

html {
    scroll-behavior: smooth;
}

//You can write css or scss
body {
    display: flex;
    align-items: center;
    flex-direction: column;
    font-family: "Ana's Faded Typewriter", sans-serif;
    background-color: rgb(0, 0, 0);
    color: white;
    text-align: center;
    font-size: 16px;
}
.hero {
    display: flex;
    flex-direction: column;
    height: 100vh;
    box-sizing: border-box;
    color: white;
    // background: url(https://images.unsplash.com/photo-1500417148159-68083bd7333a) center center no-repeat;
    background-size: cover;
    background: rgb(0, 0, 0);
    width: 100%;
}

#header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.hero_content {
    display: flex;
    width: 90%;
    height: 100%;
    justify-content: center;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
}

.txt {
    font-size: 7em;
    font-family: "Ana's Faded Typewriter";
}

.hero-title {
    max-width: 16em;
    margin: 0;
    font-size: 12vh;
    font-weight: bold;
    line-height: 0.9;
}

@media only screen and (min-width: 32em) {
    .hero-title {
        font-size: 16vh;
    }
}

.hero-footer {
    display: flex;
    margin-bottom: 1em;
}

article {
    max-width: 36em;
    margin: 0 auto;
    padding: 5em 1em;
    text-align: left;

    #anchor {
        font-family: "Ana's Faded Typewriter";
    }
    p {
        font-weight: 100;
        font-family: "Ana's Faded Typewriter";
        padding-bottom: 15px;
        line-height: 1.7;
        font-size: 17px;
        color: #eee;
    }

    a {
        color: white;
        text-decoration: none;
        // padding: 10px 0px;
    }
    a:hover {
        text-decoration: underline;
    }
}

.button-down {
    width: 50px;
    height: 50px;
    justify-self: center;
    align-self: center;
    bottom: 10px;
    position: relative;
    z-index: 1;

    a {
        cursor: pointer;
    }
}

.menu {
    top: 0;
    right: 0;
    position: fixed;
    background-color: rgb(0, 0, 0);
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 20px 20px;
    z-index: 100;

    a {
        font-size: 0.8rem;
        color: white;
        font-weight: 100;
        text-transform: uppercase;
        text-decoration: none;
        padding: 15px 20px;
        display: inline-block;
        text-align: center;
    }

    a:hover {
        background: rgba(57, 57, 57, 0.75);
    }
}
.item a {
    text-transform: capitalize;
}

@media (min-width: 900px) {
    .navbar {
        display: flex;
        .left,
        .right {
            display: flex;
            flex: 1;
        }
        .link {
            width: auto;
        }
        .right {
            justify-content: flex-end;
        }
        .toggle {
            display: none;
        }
    }
}

.navbar {
    div {
        display: none;
        @media (min-width: 900px) {
            display: flex;
        }
    }
}

@media (max-width: 900px) {
    .menu.active {
        border-bottom: 1px solid white;
    }
    .navbar.active {
        .toggle {
            color: white;
        }
        div {
            display: block;
        }
    }
    .item {
        border-bottom: 1px solid rgb(75, 72, 72);
        a {
            padding: 10;
        }

        &:hover {
            opacity: 0.9;
            a {
                background: transparent;
            }
        }
    }

    .item {
        .nav-subtext {
            position: relative;
            top: 0;
            right: 0;
            background: transparent;
            padding: 15px 20px;
            padding-top: 0px;
            font-size: 14px;
            opacity: 1;
            transition: none;
            font-family: "Roboto", sans-serif;
        }

        .right {
            right: 0;
        }

        &:hover .nav-subtext {
            opacity: 1;
            top: 0 !important;
        }
    }
    .item:nth-child(5) {
        border-bottom: 0px solid rgb(75, 72, 72);
    }
}

/* Mobile menu */
.menu {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}
.toggle {
    order: 1;
}
.item.button {
    order: 2;
}
.item {
    width: 100%;
    text-align: center;
    order: 3;
    display: none;
}
.item.active {
    display: block;
}
// /* Tablet menu */
// @media all and (min-width: 600px) {
//   .menu {
//     justify-content: flex-end;
//   }

//   .toggle {
//     flex: 1;
//     text-align: right;
//   }
//   .toggle {
//     order: 2;
//   }

// }
/* Desktop menu */
@media all and (min-width: 900px) {
    .menu {
        justify-content: flex-end;
    }
    .item {
        display: block;
        width: auto;
    }
    .toggle {
        display: none;
    }
    .logo {
        order: 0;
    }
    .item {
        order: 1;
    }
    .button {
        order: 2;
    }
    .menu li {
        padding: 15px 10px;
    }
    .menu li.button {
        padding-right: 0;
    }
}

.nav-subtext {
    position: absolute;
    top: 100px;
    right: 115px;
    background: rgb(45, 45, 45);
    padding: 15px 20px;
    opacity: 0;
    font-size: 14px;
    opacity: 0;
    transition: all 0.3s ease-in;
    font-family: "Roboto", sans-serif;
    letter-spacing: 0.1em;
    font-weight: 100;
}
.right {
    right: 30px;
}
.item:hover .nav-subtext {
    opacity: 1;
    top: 90px;
}
.roboto {
    font-family: "Roboto", sans-serif;
    a {
        color: white;
        font-weight: 400;
    }
}
a.chevron-down:hover {
    img {
        fill: red;
    }
}

p {
    display: flex;
    align-items: center;
}
.material-symbols-outlined {
    margin-right: 10px;
}

.email {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    padding: 5px 0;
    position: relative;
    svg {
        margin-right: 10px;
    }
    p {
        padding: 0 10px;
    }
}

.contact {
    padding: 5px 0;
    position: relative;
}
